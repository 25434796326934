.store-card {
	/* background-color: hsl(40, 42%, 90%); */
	background-color: rgb(255, 255, 255);
	width: max(16vw, 160px);
	/* box-shadow: 0px 10px 9px 0px rgba(0, 0, 0, 0.200); */
	border-radius: 15px 0px 0px 0px;
}

.card-image {
	background-color: rgb(255, 255, 255);
	width: 100%;
	height: max(9vw, 90px);
	padding: 0px;
	margin: 0px;
	border-radius: 15px 0px 0px 0px;
	overflow: hidden;
}

.card-image img {
	max-width: 100%;
	max-height: 100%;
	object-fit: contain;
}

.card-info {
	height: calc(100% - max(9vw, 90px));
	color: black;
}

.card-info p {
	margin-bottom: 0px;
}

.card-info .fas {
	color: black;
}