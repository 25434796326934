.double-photo {
  height: 100%;
}

.photo {
  height: 47.5%;
}

.photo img {
  max-height: max(15vw, 25vh);
  max-width: 100%;
  object-fit: contain;
}
