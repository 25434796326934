.footer {
	background-color: #213967;
	color: #b99755;
}

.footer a{
	color: #b99755;
}

#footer-logo {
	max-width: 200px;
}

#footer-divider {
	position: relative;
	width: 5px;
	height: 200px;
	background-color: #b99755;
}

.divider-end {
	position: absolute;
	width: 100%;
	height: 5px;
	border-radius: 50%;
	background-color: #b99755;
}

#divider-end-top {
	top: -2.5px;
}

#divider-end-bottom {
	top: 197.5px;
}

#rule {
	background-color: #b99755;
}

footer .fas {
	color: #b99755;
}