html, body {
	max-width: 100%;
	overflow-x: hidden;
}

#root {
	background-color: transparent;
	display: flex;
 	min-height: 100vh;
 	flex-direction: column;
}

#body {
	background-color: transparent;
	/* background-color: hsl(219, 51%, 87.5%); */
	/* font-family: 'Ubuntu', sans-serif; */
	/* font-family: 'Josefin Sans', sans-serif; */
	font-family: 'Josefin Sans', sans-serif;
	padding-top: 70px;
}

h1, h2, h3, h4, h5, h6 {
	font-family: 'Oswald', sans-serif;
	text-transform: uppercase;
}

main {
	/* background-color: hsl(40, 42%, 53%); */
	margin-left: 12.5vw;
	margin-right: 12.5vw;
}

@media (max-width: 768px) {
	main {
		margin-left: 10px;
		margin-right: 10px;
	}
}

#root hr {
	height: 2px;
	opacity: 1;
	color: #213967;
	border: none;
}

#root a {
	text-decoration: none;
}