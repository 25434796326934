.navbar {
	/* background-color: hsl(219, 51%, 27%); */
	background-color: white;
	border-bottom: 5px solid #213967;
}

@media (max-width: 768px) {
	.navbar {
		padding-left: 100px;
		padding-right: 100px;
	}
}

#root .nav-link:hover{
	background-color: #b99755;
	color: #213967;
}

#root .nav-link {
	font-family: 'Oswald', sans-serif;
	text-transform: uppercase;
	text-decoration: none;
	color: #b99755;
	font-weight: 600;
}

#root	.navbar-toggler {
	border-style: none;
	box-shadow: none;
}

#root .navbar-toggler-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(185, 151, 85, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.navbar-toggler .fas {
	color: #b99755;
}