hr {
	height: 10px;
	opacity: .75;
	color: #213967;
	border: none;
}

#video-container {
	display: block;
	background-color: #213967;
	position: relative;
  padding-top: 55.8%
}

.react-player {
	position: absolute;
  top: 0;
  left: 0;
}

.double-border {
	border-width: 1vw;
	border-style: double;
	border-color: #b99755;
}

@media (min-width: 768px) {
	.double-border {
		border-width: 0.66vw;
	}
}

.carousel {
	background-color: #213967;
	width: 100vw;
	overflow: hidden;
}

@media (min-width: 768px) {
	.carousel {
		height: 35vw;	
		width: 25vw;
	}
}

.carousel-inner, .carousel-item {
	height: 100%;
}

.carousel-control-next .fas {
	font-size: 40px;
	color: #b99755;
}

.carousel-control-prev .fas {
	font-size: 40px;
	color: #b99755;
}